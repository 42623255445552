import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import '../index.css';

const Leaderboard = () => {
  const [enrollments, setEnrollments] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [goal, setGoal] = useState(500000); // Initial default value

  useEffect(() => {
    const enrollmentsRef = collection(db, 'enrollments');
    const unsubscribe = onSnapshot(enrollmentsRef, (snapshot) => {
      const enrollmentsData = snapshot.docs.map(doc => doc.data());
      const sortedEnrollments = enrollmentsData.sort((a, b) => parseFloat(b.monthAmount) - parseFloat(a.monthAmount));
      setEnrollments(sortedEnrollments);
    }, (error) => {
      console.error('Error fetching enrollments: ', error);
    });

    return () => unsubscribe();
  }, []);

  const carImages = [
    'sloth.png', 'turtle.png', 'dog.png', 'donkey.png', 'fasterDog.png', 'horse.png', 'cheetah.png', 
    'bike.png', 'scooter.png', 'electricBike.png', 'moped.png', 'golfCart.png', 'gokart.png', 'sedan.png',
    'Coupe.png', 'motorcycle.png', 'sportCar.png', 'rallyCar.png', 'raceCar.png', 'Lambo.png', 'f1Car.png', 
    'Osprey.png', 'Jet.png', 'FighterJet.png'
  ];

  const getCarImage = (amount) => {
    const percentage = (amount / goal) * 100;
    const imageIndex = Math.floor(percentage / 5);
    return `/images/${carImages[imageIndex]}`;
  };

  const getProgressColor = (amount, goal) => {
    const percentage = (amount / goal) * 100;
    if (percentage >= 100) {
      return 'purple';
    } else if (percentage >= 75) {
      return 'green';
    } else if (percentage >= 50) {
      return 'orange';
    } else if (percentage >= 25) {
      return 'yellow';
    } else {
      return 'red';
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleGoalChange = (e) => {
    const sanitizedValue = e.target.value.replace(/,/g, ''); // Remove any commas
    setGoal(Number(sanitizedValue));
  };

  // Helper function to format numbers with commas
  const formatNumber = (num) => {
    if (Number.isNaN(num)) return '0.00';
    return parseFloat(num).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className={`leaderboard ${isFullscreen ? 'fullscreen' : ''}`}>
      
        <Link to="/admin" className="back-button">Admin Page</Link>
        <Link to="/admin/enrollments" className="back-button">Enrollments</Link>
        <Link to="/taxleaderboard" className="back-button">Tax Leaderboard</Link> {/* New button for TaxLeaderboard */}
        
        {!isFullscreen && <button onClick={toggleFullscreen} className="fullscreen-button">Fullscreen</button>}
        {isFullscreen && <button onClick={toggleFullscreen} className="exit-button">Exit Fullscreen</button>}
      
      {!isFullscreen && (
        <div className="goal-input">
          <label htmlFor="goal">Set Goal: $</label>
          <input 
            type="number" 
            id="goal" 
            value={goal} 
            onChange={handleGoalChange} 
          />
        </div>
      )}
      {isFullscreen && (
        <div className="goal-display">
          <h2>Goal: ${formatNumber(goal)}</h2>
        </div>
      )}
      <div className={`leaderboard-container ${isFullscreen ? 'fullscreen-container' : ''}`}>
        <div className="leaderboard-items">
          {enrollments.map((agent, index) => (
            <div key={index} className="leaderboard-item">
              <div className="leaderboard-details">
                <div className="leaderboard-name">{agent.fname} {agent.lname}</div>
                <div className="leaderboard-progress">
                  <div className="progress-bar-container">
                    <div 
                      className={`progress-bar ${getProgressColor(agent.monthAmount, goal)}`} 
                      style={{ width: `${(parseFloat(agent.monthAmount) / goal) * 62.5}%` }}
                    >
                      <img src={getCarImage(parseFloat(agent.monthAmount))} alt={`Car ${index + 1}`} className="car-image" />
                    </div>
                    <div className="goal-line" style={{ left: '62.5%' }}></div>
                    <div className="finish-line" style={{ left: '100%' }}></div>
                  </div>
                </div>
                <div className="leaderboard-amount">${formatNumber(agent.monthAmount)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;