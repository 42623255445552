import React, { useState, useEffect } from 'react';
import { db, auth, createUserWithEmailAndPassword } from '../firebase'; // Update the path to your firebase.js file
import { collection, getDocs, doc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function UserModal() {
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [department, setDepartment] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showUsers, setShowUsers] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users: ", error);
      setError("Error fetching users.");
    }
    setLoading(false);
  };

  const createUser = async () => {
    if (!email || !password || !fname || !lname || !department || !role) {
      setError('Please fill in all fields');
      return;
    }

    if (password.length < 6 || !/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password)) {
      setError('Password must be at least 6 characters and include at least one uppercase letter, one lowercase letter, and one number.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;

      await setDoc(doc(db, 'users', user.uid), {
        id: user.uid, // Use the user's UID as the document ID
        createdAt: new Date(),
        department,
        email,
        fname,
        lname,
        role,
      });

      if (department === 'debt') {
        await setDoc(doc(db, 'enrollments', user.uid), {
          id: user.uid,
          cancellationAmount: "0",
          cancellationCount: "0",
          dayAmount: "0",
          dayCount: "0",
          email,
          fname,
          lname,
          monthAmount: "0",
          pendingAmount: "0",
          pendingCount: "0",
          quarterAmount: "0",
          weekAmount: "0",
          weekCount: "0",
        });
      }
  
      fetchUsers(); // Refresh the list of users
      resetForm();
      window.alert('User created successfully!');
    } catch (error) {
      console.error('Error creating user:', error);
      setError('Error creating user. Please try again.');
    }
    setLoading(false);
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setFname('');
    setLname('');
    setDepartment('');
    setRole('');
  };

  const deleteUser = async (userId) => {
    try {
      await deleteDoc(doc(db, 'users', userId));
      console.log('User deleted successfully');
      fetchUsers(); // Refresh the list of users
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Error deleting user. Please try again.');
    }
  };

  const confirmDelete = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUser(userId);
    }
  };

  const handleShowUsers = () => {
    setShowUsers(!showUsers);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const startEditing = (user) => {
    setEditingUser(user);
    setEmail(user.email);
    setFname(user.fname);
    setLname(user.lname);
    setDepartment(user.department);
    setRole(user.role);
  };

  const updateUser = async () => {
    if (!email || !fname || !lname || !department || !role) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const userRef = doc(db, 'users', editingUser.id);
      await updateDoc(userRef, {
        email,
        fname,
        lname,
        department,
        role,
      });

      fetchUsers(); // Refresh the list of users
      resetForm();
      setEditingUser(null);
      window.alert('User updated successfully!');
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Error updating user. Please try again.');
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>User Management</h1>
      <button onClick={() => navigate('/admin')}>Back to Admin Page</button>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      <h2>{editingUser ? 'Edit User' : 'Create New User'}</h2>
      <div>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={!!editingUser} />
        {!editingUser && (
          <>
            <input type={showPassword ? 'text' : 'password'} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button onClick={togglePasswordVisibility}>{showPassword ? 'Hide' : 'Show'} Password</button>
          </>
        )}
        <input type="text" placeholder="First Name" value={fname} onChange={(e) => setFname(e.target.value)} />
        <input type="text" placeholder="Last Name" value={lname} onChange={(e) => setLname(e.target.value)} />
        <select value={department} onChange={(e) => setDepartment(e.target.value)}>
          <option value="">Select Department</option>
          <option value="tax">Tax</option>
          <option value="debt">Debt</option>
        </select>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="">Select Role</option>
          <option value="admin">Admin</option>
          <option value="agent">Agent</option>
        </select>
        <button onClick={editingUser ? updateUser : createUser} disabled={loading}>
          {editingUser ? 'Update User' : 'Create User'}
        </button>
        {editingUser && (
          <button onClick={() => { resetForm(); setEditingUser(null); }}>
            Cancel Edit
          </button>
        )}
      </div>

      <div style={{ marginTop: '20px' }}>
        <button onClick={handleShowUsers}>
          {showUsers ? 'Hide Users' : 'Manage Existing Users'}
        </button>
      </div>

      {showUsers && (
        <table className="user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{user.fname} {user.lname}</td>
                <td>{user.email}</td>
                <td>
                  <button onClick={() => startEditing(user)}>Edit</button>
                  <button className="delete-button" onClick={() => confirmDelete(user.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default UserModal;
