import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import LoginPage from './pages/LoginPage';
import AgentPage from './pages/AgentPage';
import AdminPage from './pages/AdminPage';
import EnrollmentPage from './pages/EnrollmentPage';
import Leaderboard from './pages/Leaderboard';
import TaxLeaderboard from './pages/TaxLeaderboard'; // Import the TaxLeaderboard component
import UserModal from './pages/UserModal';
import ClientInput from './pages/ClientInput';
import TaxEnrollmentPage from './pages/TaxEnrollmentPage'; // Import the new page
import { setUser, clearUser } from './redux'; // Ensure this path is correct

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const auth = getAuth();
  const user = useSelector(state => state.user);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        };
        dispatch(setUser(userData));
        localStorage.setItem('user', JSON.stringify(userData));
      } else {
        dispatch(clearUser());
        localStorage.removeItem('user');
      }
      setLoading(false);
    });

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      dispatch(setUser(JSON.parse(storedUser)));
    } else {
      setLoading(false);
    }

    return () => unsubscribe();
  }, [auth, dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/agent" element={user ? <AgentPage /> : <Navigate to="/" />} />
      <Route path="/admin" element={user ? <AdminPage /> : <Navigate to="/" />} />
      <Route path="/admin/enrollments" element={user ? <EnrollmentPage /> : <Navigate to="/" />} />
      <Route path="/admin/taxEnrollments" element={user ? <TaxEnrollmentPage /> : <Navigate to="/" />} /> {/* Added TaxEnrollmentPage route */}
      <Route path="/leaderboard" element={user ? <Leaderboard /> : <Navigate to="/" />} />
      <Route path="/taxleaderboard" element={user ? <TaxLeaderboard /> : <Navigate to="/" />} />
      <Route path="/users" element={user ? <UserModal /> : <Navigate to="/" />} />
      <Route path="/clientinput" element={<ClientInput />} /> {/* Make this route public */}
    </Routes>
  );
}

export default App;